import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Checkbox, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';
import { showAddUserDlg } from './AddUserDlg/showAddUserDlg';


const User = ({ history }) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login');
    }
  }, [history]);

  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [newItemKey, setNewItemKey] = useState(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length}
          checked={selectedRowKeys.length === dataSource.length}
          onChange={(e) => {
            const checked = e.target.checked;
            const newSelectedKeys = checked ? dataSource.map(item => item.key) : [];
            setSelectedRowKeys(newSelectedKeys);
          }}
        />
      ),
      dataIndex: 'checkbox',
      render: (_, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelectedRowKeys(prev =>
              checked ? [...prev, record.key] : prev.filter(key => key !== record.key)
            );
          }}
        />
      ),
    },
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, __, index) => index + 1 + (pagination.current - 1) * pagination.pageSize,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      width: '10%',
      render: (text, record) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      width: '10%',
      render: (text, record) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Birthday',
      dataIndex: 'birthday',
      render: (text, record) => {
        return <span>{text ? text : ''}</span>
      }
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (text, record) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Ip Address',
      dataIndex: 'ip_address',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Access Time',
      dataIndex: 'access_time',
      render: (date) => date ? moment(date).format('DD-MM-YYYY HH:MM') : '',
    },
    {
      title: 'Subscription Status',
      dataIndex: 'subscription_status',
      render: (text, record) => {
        return record.subscription_status == "Active" ? (
          <Tag style={{ color: 'white', backgroundColor: 'green', padding: '2px 6px', border: 'none', width: '55px', textAlign: 'center' }}>
            {record.subscription_status}
          </Tag>
        ) : (
          <Tag style={{ color: 'white', backgroundColor: '#F83535', padding: '2px 6px', border: 'none', width: '55px' }}>
            {record.subscription_status}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        return record.status == 1 ? (
          <Tag style={{ color: 'white', backgroundColor: 'green', padding: '2px 6px', border: 'none', width: '55px', textAlign: 'center' }}>
            Verified
          </Tag>
        ) : (
          <Tag style={{ color: 'white', backgroundColor: '#F83535', padding: '2px 6px', border: 'none', width: '55px' }}>
            Non Verified
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return <>
          <Button onClick={() => edit(record)} type="link">
            <EditOutlined style={{ fontSize: '20px' }} />
          </Button>
          <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
            <Button type="link">
              <DeleteOutlined style={{ fontSize: '20px', color: 'red' }} />
            </Button>
          </Popconfirm>
        </>;
      },
    },
  ];


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login');
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/user`);
      const fetchedData = response.data
        .filter(item => item.is_deleted != 1)
        .map((item, index) => ({
          ...item,
          key: String(item.id),
        }));

      setDataSource(fetchedData);
      setFilteredData(fetchedData);
    } catch (err) {
      Toast('There was an error on server!', 2);
    }
  };
  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      Toast('No users selected for deletion.', 2);
      return;
    }

    try {
      const idsToDelete = selectedRowKeys;
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/user/bulk-delete`, {
        data: { ids: idsToDelete },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.status === 'success') {
        const updatedData = dataSource.filter(item => !idsToDelete.includes(item.key));
        setDataSource(updatedData);
        setFilteredData(updatedData);
        setSelectedRowKeys([]);

        Toast('Selected users deleted successfully!', 1);
      } else {
        Toast('Failed to delete selected users.', 2);
      }
    } catch (error) {
      console.error('Bulk delete error:', error);
      Toast('There was an error deleting users.', 2);
    }
  };





  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setFilteredData(newData);

    const total = newData.length;
    const current = pagination.current > Math.ceil(total / pagination.pageSize) ? Math.ceil(total / pagination.pageSize) : pagination.current;
    setPagination({ ...pagination, total, current });

    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/user/${key}`);

    if (response.data.status == "success") {
      Toast('Deleted successfully!', 1);
    } else {
      Toast('There was an error on server!', 2);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };


  const handleAdd = async () => {
    let dlgRes = await showAddUserDlg({
      title: "Add User"
    })
    if (dlgRes == null) return
    Toast('Added successfully!', 1);
    fetchData();
  }
  const edit = async (record) => {
    let dlgRes = await showAddUserDlg({
      title: "Update User",
      userData: record
    })
    if (dlgRes == null) return
    Toast('Updated successfully!', 1);
    fetchData();
  };
  return (
    <AdminLayout>
      <Row className="table-nav">
        <Col className="d-flex">
          {selectedRowKeys.length > 0 && (
            <Button
              type="primary"
              danger
              onClick={handleBulkDelete}
              icon={<DeleteOutlined />}
              style={{ marginRight: '16px', marginTop: '10px', height: '33px' }}
            >
              Delete {selectedRowKeys.length} {selectedRowKeys.length > 1 ? 'Users' : 'User'}
            </Button>
          )}
          <Input
            className="mt-10"
            placeholder="Search..."
            allowClear={true}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: 10 }}
          />
        </Col>
        <Col><Button type="primary" onClick={handleAdd} style={{ height: '38px' }}>
          Add
        </Button></Col>
      </Row>
      <div className="admin-user">
        <Form form={form} component={false}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              ...pagination,
              showSizeChanger: true,
              onChange: (page, pageSize) => setPagination({ ...pagination, current: page, pageSize }),
            }}
            rowClassName="editable-row"
            rowKey="key"
            scroll={{ x: 'max-content' }}
          />
        </Form>
      </div>

    </AdminLayout>
  );
};

export default User;