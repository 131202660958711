import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { numberWithCommas } from "constants/global";
import { Button, Spin, notification } from "antd";
import axios from 'axios';
import "./StripeForm.css";

export default function SubscriptionForm(props) {
    const { selSubscription, email, onPayFinished, termCheck } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onPay = async (event) => {
        if (!termCheck) {
            notification.error({
                message: 'Error Notification',
                description: "Please check terms & condition",
            });
            return;
        }
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (error) {
            setMessage(error.message);
            setLoading(false);
            return;
        }
        
        try {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("token", paymentMethod.id);
            formData.append("subscription_plan_id", selSubscription.id);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/subscribe`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            notification.config({
                placement: 'topRight', // Set the position to bottom left
                duration: 2,
            });
            if (response.data.status === "success") {
                notification.success({
                    message: 'Success Notification',
                    description: "Subscription Created Successfully!",
                });
                setTimeout(() => {
                    onPayFinished(response.data.data)
                }, 1500)
            } else {
                notification.error({
                    message: 'Error Notification',
                    description: response.data.message,
                });
            }
        } catch (error) {
            setMessage("Error processing subscription");
        }

        setLoading(false);
    };

    return (
        <Spin spinning={loading}>
            <div className="card-rect">
                <CardElement options={{
                    style: {
                        base: {
                            fontSize: '18px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                            lineHeight: '50px'
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }} />
            </div>
            <div style={{ textAlign: 'center' }}>
                <Button type="primary" onClick={onPay} block style={{ width: '240px', padding: '20px', fontSize: 20 }}>
                    Confirm and pay ${numberWithCommas(parseFloat(selSubscription.year_fee) + parseFloat(selSubscription.week_fee) * 2)}
                </Button>
            </div>
        </Spin>
    );
}
