import React, { useState, useEffect } from 'react';
import { confirmable } from "react-confirm";
import { Modal, Button, Col, Input, Row, notification } from 'antd';
import Toast from 'layouts/Partials/Toast';
import axios from 'axios';
import { isEmpty } from "constants/global";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./UpdateSubscribeDlg.css";
import SubscriptionOrder from '../SubscriptionOrder';
import SubscriptionForm from '../SubscriptionForm';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);


function UpdateSubscribeDlg(props) {
    const { show, proceed, selSubscription } = props;

    const [loading, setLoading] = useState([]);
    const [termCheck, setTermCheck] = useState(false);

    const onPayFinished = () => {
        localStorage.setItem("subscription_status", "active");
        proceed(true);
    }
    return (
        <Modal
            visible={show}
            destroyOnClose={true}
            onCancel={() => {
                proceed(null);
            }}
            className="update-subscription-modal"
            closable={true}
            maskClosable={false}
            title={"Update Subscription"}
            footer={null}
        >
            <div>
                <SubscriptionOrder
                    selSubscription={selSubscription}
                    termCheck={termCheck}
                    setTermCheck={setTermCheck}
                    loading={loading}
                    setLoading={setLoading}
                />
                <div>
                    <Elements stripe={stripePromise}>
                        <SubscriptionForm
                            onPayFinished={onPayFinished}
                            selSubscription={selSubscription}
                            email={localStorage.getItem("email")}
                            loading={loading}
                            setLoading={setLoading}
                            termCheck={termCheck} />
                    </Elements>
                </div>
            </div>
        </Modal>
    );
}

export default confirmable(UpdateSubscribeDlg);
