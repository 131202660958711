import React, { useState, useEffect } from 'react';
import { confirmable } from "react-confirm";
import { Modal, Button, Col, Input, Radio, Row, Select, message, Typography, Upload, DatePicker, Form, notification, Switch } from 'antd';
import Toast from 'layouts/Partials/Toast';
import axios from 'axios';
import dayjs from 'dayjs';
import { capitalizeFirstLetter, formatToDDMMYYYY, isEmpty, openDangerNotification, ValidateEmail } from "constants/global";

import "./AddUserDlg.css";
import UserPersonalRect from './UserPersonalRect';
import UserParentRect from './UserParentRect';
const { Option } = Select;
const { Text } = Typography;

function AddUserDlg(props) {
    const { show, proceed, title, userData } = props;


    const [formData, setFormData] = useState({});

    const [errorFields, setErrorFields] = useState([]);

    useEffect(() => {
        if (userData) {
            setFormData({ ...formData, ...userData, is_default_password: false, password: "" })
        } else {
            setFormData({
                id: '',
                first_name: '',
                last_name: '',
                birthday: '',
                address: '',
                email: '',
                password: '',
                is_child: 'individual',
                phone: "",
                email: "",
                note: "",
                confirm_password: '',
                is_default_password: false
            })
        }
    }, [userData])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (errorFields.includes(name)) {
            let errors = errorFields.filter((x) => x != name);
            setErrorFields([...errors]);
        }
        let tmpVal = value;
        if (name.includes("name")) {
            tmpVal = capitalizeFirstLetter(value)
        } else {
            tmpVal = value
        }

        setFormData({ ...formData, [name]: tmpVal });
    };

    const validateFields = () => {
        var errorList = Array();

        errorList = isEmpty(formData, "first_name", errorList);
        errorList = isEmpty(formData, "last_name", errorList);
        errorList = isEmpty(formData, "birthday", errorList);
        if (formData.is_child == "parent") {
            errorList = isEmpty(formData, "gender", errorList);
        }
        errorList = isEmpty(formData, "phone", errorList);
        errorList = isEmpty(formData, "email", errorList);
        if (!errorList.includes("email")) {
            if (!ValidateEmail(formData.email)) {
                errorList = [...errorList, "email"]
            }
        }
        errorList = isEmpty(formData, "address", errorList);
        if (formData.is_child == "parent") {
            errorList = isEmpty(formData, "note", errorList);
        }

        if (formData.is_default_password) {
            errorList = isEmpty(formData, "password", errorList);
            errorList = isEmpty(formData, "confirm_password", errorList);
            if (formData.password != formData.confirm_password) {
                errorList = [...errorList, "confirm_password"]
            }
        }
        setErrorFields([...errorList]);
        return errorList.length == 0;
    }
    const doRegister = async () => {
        if (validateFields()) {

            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const userIp = ipResponse.data.ip;

            const formDataToSend = new FormData();
            formDataToSend.append("id", formData.id);
            formDataToSend.append("firstName", formData.first_name);
            formDataToSend.append("lastName", formData.last_name);
            formDataToSend.append("dateOfBirth", formData.birthday);
            formDataToSend.append("homeAddress", formData.address);
            formDataToSend.append("password", formData.password);
            formDataToSend.append("phone", formData.phone);
            formDataToSend.append("email", formData.email);
            formDataToSend.append("isChild", formData.is_child);
            formDataToSend.append("gender", formData.gender);
            formDataToSend.append("note", formData.note);
            formDataToSend.append("ipAddress", userIp);
            formDataToSend.append("subscriptionId", "");
            formDataToSend.append("is_default_password", formData.is_default_password);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/user/create_by_admin`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    proceed(true);
                } else {
                    openDangerNotification(2);
                }
            } catch (error) {
                openDangerNotification(3);
            }
        } else {
            Toast("Please confirm your information again", 2);
        }
    }

    return (
        <Modal
            visible={show}
            destroyOnClose={true}
            onCancel={() => {
                proceed(null);
            }}
            className="add-user-modal"
            closable={true}
            maskClosable={false}
            title={title}
            footer={null}
        >
            <div>
                <div className="signup-form-personal" style={{ padding: 20 }}>
                    <Radio.Group onChange={(e) => {
                        setFormData({ ...formData, is_child: e.target.value });
                    }} value={formData.is_child} className="signup-custom-radio-group">
                        <Row gutter={[12, 12]}>
                            <Col xs={24} md={12}>
                                <Radio.Button value="individual">Individual User Account</Radio.Button>
                            </Col>
                            <Col xs={24} md={12}>
                                <Radio.Button value="parent">Parent/Guardian Account</Radio.Button>
                            </Col>
                        </Row>
                    </Radio.Group>
                </div>
                <div>
                    {formData.is_child == "individual" ?
                        <UserPersonalRect
                            userData={userData}
                            formData={formData}
                            setFormData={setFormData}
                            errorFields={errorFields}
                            setErrorFields={setErrorFields} /> :
                        <UserParentRect
                            userData={userData}
                            formData={formData}
                            setFormData={setFormData}
                            errorFields={errorFields}
                            setErrorFields={setErrorFields} />}
                </div>
                <Row justify={'center'} align={'middle'} style={{ marginTop: 20, textAlign: 'center' }}>
                    <Col xs={24} md={24}>
                        <Button type="primary" onClick={doRegister} htmlType="submit" block className="add-user-button">
                            {userData ? "Update" : "Add"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default confirmable(AddUserDlg);
