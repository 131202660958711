import React, { useState } from "react";
import { Row, Col, Typography, Input, DatePicker, Checkbox } from "antd";
import { numberWithCommas } from "constants/global";


const { Link } = Typography;
const SubscriptionOrder = (props) => {
    const { selSubscription, termCheck, setTermCheck, loading, setLoading } = props;
    const termsPdfUrl = `${process.env.REACT_APP_API_BASE_URL}uploads/media/241025 Terms & Conditions.pdf`;



    return <div className="signup-form-order">
        <div className="payment">
            <Row justify={"space-between"}>
                <Col className="payment-label">Subscription Annual Fee</Col>
                <Col className="payment-amount">${numberWithCommas(selSubscription.year_fee)}</Col>
            </Row>
            <Row justify={"space-between"}>
                <Col className="payment-label">Subscription Fortnightly Fee</Col>
                <Col className="payment-amount">${numberWithCommas(parseFloat(selSubscription.week_fee) * 2)}</Col>
            </Row>
            <div className="payment-total">
                <Row justify={"space-between"}>
                    <Col className="payment-total-label">Checkout Total</Col>
                    <Col className="payment-total-label">${numberWithCommas(parseFloat(selSubscription.year_fee) + parseFloat(selSubscription.week_fee) * 2)}</Col>
                </Row>
            </div>
            <div className="payment-check-rect">
                <Checkbox
                    checked={termCheck}
                    className="pay-check"
                    onChange={(e) => {
                        setTermCheck(e.target.checked)
                    }}
                >
                    <Link href={termsPdfUrl} target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </Link>
                </Checkbox>
            </div>
        </div>
    </div>
}
export default SubscriptionOrder;