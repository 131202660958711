import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Select, Tabs, Modal, message } from 'antd';
import Toast from 'layouts/Partials/Toast';
import './index.css';
import UserLayout from 'layouts/UserLayout/UserLayout';
import MembershipWidget from 'components/MembershipWidget/MembershipWidget';
import { showUpdateSubscribeDlg } from 'components/Stripe/UpdateSubscribeDlg/showUpdateSubscribeDlg';

const Subscription = ({ history }) => {

  const [subscriptionList, setSubscriptionList] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [selSubscription, setSelSubscription] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('client_token');
    if (!token) {
      history.push('/signin');
    } else {
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('user_id', localStorage.getItem("user_id"));
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/get-subscription`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('client_token')}`
        }
      });
      let tmpList = [...response.data.data]
      console.log("aaaa:", tmpList)
      let nTmpList = tmpList.filter((x) => x.checked == true)
      if (nTmpList.length > 0) {
        setCurrentSubscription({ ...nTmpList[0] })
        setSelSubscription({ ...nTmpList[0] })
      }
      console.log("dddfdL", nTmpList)
      setSubscriptionList(tmpList)
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {

  }
  const handleUpdate = async () => {
    if (selSubscription == null || subscriptionList.filter((x) => x.checked).length == 0) {
      Toast("Please select plan", 2);
      return;
    }
    if (currentSubscription) {
      onUpdateSubscription()
    } else {
      let dlgRes = await showUpdateSubscribeDlg({
        selSubscription: selSubscription
      })
      if (dlgRes == null) return;
      fetchData();
    }
  }

  const onUpdateSubscription = async () => {
    const formData = new FormData();
    formData.append('user_id', localStorage.getItem("user_id"));
    formData.append('email', localStorage.getItem("email"));
    formData.append('subscription_plan_id', selSubscription.id);

    let dataUrl = `${process.env.REACT_APP_API_BASE_URL}api/update-subscribe`;
    try {
      const response = await axios.post(dataUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('client_token')}`
        }
      });
      if (response.data.status === "success") {
        Toast("Subscription updated successfully", 1);
        localStorage.setItem("subscription_status", "active");
      } else {
        Toast(response.data.message, 2);
      }

    } catch (err) {
      Toast('There was an error on server!', 2);
    }
  }

  return (
    <UserLayout>
      <div className='page-title'>Subscription</div>
      <Row className="table-nav" align={'middle'}>
        <Col className='d-flex content-title'>
          Membership: {currentSubscription ? localStorage.getItem("subscription_status") : "N/A"}
        </Col>
        <Col>
          {currentSubscription?.id == selSubscription?.id ?
            <>
              {currentSubscription ? <Button type="primary" onClick={handleCancel} style={{ height: '38px', background: '#a7192f', color: 'white', fontWeight: 600 }}>
                Cancel Subscription
              </Button> : <></>}
            </> : selSubscription ? <Button type="primary" onClick={handleUpdate} style={{ height: '38px', fontWeight: 600 }}>
              Update Subscription
            </Button> : <></>}
        </Col>
      </Row>
      <div style={{ marginTop: 20 }}>
        <MembershipWidget
          subscriptionList={subscriptionList}
          setSubscriptionList={setSubscriptionList}
          currentSubscription={currentSubscription}
          selSubscription={selSubscription}
          setSelSubscription={setSelSubscription}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </UserLayout>
  );
};

export default Subscription;