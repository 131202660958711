import React, { useState } from "react";
import { Row, Col, Form, Input, DatePicker } from "antd";
import "./MembershipWidget.css";
const MembershipWidget = (props) => {
    const { subscriptionList, setSubscriptionList, currentSubscription, selSubscription, setSelSubscription, loading, setLoading } = props;
    const onClickItem = (index) => {
        let tmpList = [...subscriptionList]
        tmpList = tmpList.map((x, key) => {
            let tmpObj = { ...x }
            if (key == index) {
                tmpObj.checked = true;
                setSelSubscription({ ...tmpObj })
            } else {
                tmpObj.checked = false;
            }
            return tmpObj
        })
        setSubscriptionList(tmpList)
    }
    return <div><Row gutter={[24, 24]} align={"middle"}>
        {subscriptionList.map((x, index) => {
            return <Col sm={24} md={8} key={index}>
                <div className={`membership-item ${x.checked}`} onClick={() => {
                    onClickItem(index)
                }}>
                    <p className="membership-title">{x.title}</p>
                    {currentSubscription?.id == x.id && <div className="current-rect">CURRENT</div>}
                    <div className="membership-year-fee">
                        <div className="fee-rect">
                            <div className="fee-symbol">$</div>
                            <div className="fee-amount">{x.year_fee}</div>
                        </div>
                        <div className="fee-year-rect">
                            <div className="fee-year">/year</div>
                        </div>
                    </div>
                    <div className="membership-week-fee">
                        <span>Weekly Fee: </span>{x.week_fee == 0 ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;N/A</span> : <span>&nbsp;&nbsp;${x.week_fee} per week</span>}
                    </div>
                    <div className="membership-billing">
                        <div className="membership-billing-label">Billing: </div>
                        <div className="membership-billing-desc">{x.billing_desc}</div>
                    </div>
                    <div className="membership-benefit">
                        <div className="membership-benefit-label">Benefits: </div>
                        <div className="membership-benefit-desc">{x.benefit}</div>
                    </div>
                    <div className="membership-ideal">
                        <div className="membership-ideal-label"><p>Ideal&nbsp;For: </p></div>
                        <div className="membership-ideal-desc">{x.ideal}</div>
                    </div>
                </div>

            </Col>
        })}
    </Row></div>
}
export default MembershipWidget;