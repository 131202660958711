import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { Button, Col, Input, Row, Select, Radio, Typography, Upload, DatePicker, notification, Form, Spin, ConfigProvider } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, ArrowRightOutlined, ArrowLeftOutlined, LoadingOutlined, DotNetOutlined } from "@ant-design/icons";
import "../Register/Register1Page.css";
import ImgRegisterLogo from "assets/png/slide-back.jpg"
import useStyle from './useStyle';
import axios from 'axios';
import "./index.css";
import SignupPersonalRect from "./SignupPersonalRect";
import { isEmpty, numberWithCommas, ValidateEmail } from "constants/global";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionForm from "components/Stripe/SubscriptionForm";
import SignupOtp from "./SignupOtp";
import Toast from "layouts/Partials/Toast";
import SignupParentRect from "./SignupParentRect";
import MembershipWidget from "components/MembershipWidget/MembershipWidget";
import SubscriptionOrder from "components/Stripe/SubscriptionOrder";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);


const SignUp = ({ history }) => {

    const [signupResponse, setSignupResponse] = useState("");
    const [signupForm, setSignupForm] = useState({
        is_child: "individual",
        first_name: "",
        last_name: "",
        email: "",
        gender: "",
        note: "",
        birthday: "",
        phone: "",
        password: "",
        confirm_password: ""
    })

    const [subscriptionList, setSubscriptionList] = useState([]);
    const [selSubscription, setSelSubscription] = useState(null);
    const [termCheck, setTermCheck] = useState(false);
    const [step, setStep] = useState(0);
    const [verified, setVerified] = useState(false);
    const [errorFields, setErrorFields] = useState([])

    const [loading, setLoading] = useState(false);
    const { styles } = useStyle();


    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/subscription-setting`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            let tmpList = [...response.data.data]
            tmpList = tmpList.map((x, index) => {
                let tmpObj = { ...x }
                if (index == 1) {
                    tmpObj = { ...tmpObj, checked: true }
                    setSelSubscription({ ...tmpObj })
                } else {
                    tmpObj = { ...tmpObj, checked: false }
                }
                return tmpObj
            })
            setSubscriptionList(tmpList)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (verified) {
            setStep(step + 1);
        }
    }, [verified])
    const validateFields = () => {
        var errorList = Array();
        errorList = isEmpty(signupForm, "first_name", errorList);
        errorList = isEmpty(signupForm, "last_name", errorList);
        errorList = isEmpty(signupForm, "birthday", errorList);
        if (signupForm.is_child == "parent") {
            errorList = isEmpty(signupForm, "gender", errorList);
        }
        errorList = isEmpty(signupForm, "phone", errorList);
        errorList = isEmpty(signupForm, "email", errorList);
        if (!errorList.includes("email")) {
            if (!ValidateEmail(signupForm.email)) {
                errorList = [...errorList, "email"]
            }
        }
        errorList = isEmpty(signupForm, "address", errorList);
        if (signupForm.is_child == "parent") {
            errorList = isEmpty(signupForm, "note", errorList);
        }
        errorList = isEmpty(signupForm, "password", errorList);
        errorList = isEmpty(signupForm, "confirm_password", errorList);
        if (!errorList.includes("password") && !errorList.includes("confirm_password")) {
            if (signupForm.password != signupForm.confirm_password) {
                errorList = [...errorList, "confirm_password"]
            }
        }
        setErrorFields([...errorList]);
        return errorList.length == 0;
    }
    const onSubmit = async () => {
        if (loading) return
        setSignupResponse("");
        if (step == 0) {
            let validate = await validateFields();
            if (!validate) return;
        }
        if (step == 1 && !verified) {
            return;
        }
        setStep(step + 1);
    };
    const onPayFinished = (info) => {
        onSignup(info)
    }

    const onSignup = async () => {
        let subscriptionId = 0;
        let tmpList = [...subscriptionList]
        tmpList = tmpList.filter((x) => x.checked == true)
        if (tmpList.length > 0) {
            subscriptionId = tmpList[0].id
        }

        setLoading(true);

        try {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const userIp = ipResponse.data.ip;

            const formData = new FormData();
            formData.append("firstName", signupForm.first_name);
            formData.append("lastName", signupForm.last_name);
            formData.append("dateOfBirth", signupForm.birthday);
            formData.append("homeAddress", signupForm.address);
            formData.append("password", signupForm.password);
            formData.append("phone", signupForm.phone);
            formData.append("email", signupForm.email);
            formData.append("isChild", signupForm.is_child);
            formData.append("gender", signupForm.gender);
            formData.append("note", signupForm.note);
            formData.append("ipAddress", userIp);
            formData.append("subscriptionId", subscriptionId);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/signup`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            setLoading(false);
            if (response.data.res === "success") {
                Toast("Your account has been created successfully! Please login.", 1)
                setTimeout(() => {
                    window.location.href = "/signin";
                }, 1000)
            }
        } catch (error) {
            setLoading(false);
            Toast("Something went wrong.", 2)
            console.error("Signup error:", error);
        }

    }
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const handleBack = () => {
        window.history.back();
    }
    const onPrev = () => {
        setStep(step - 1)
    }
    return (
        <HomeLayout>
            <div style={{ height: 'calc(100vh + 450px)', background: '#f3f3ea' }}>
                <div className='signup-logo'>
                    <img src={ImgRegisterLogo} width="100%" />
                </div>

                <div className='signup-form auth'>
                    <h1 className="mb-30">Welcome Here!</h1>
                </div>
                <div className='register-form signup-form'>
                    <Spin spinning={loading}>
                        <div className='register-form-title'>
                            {step == 0 && <h1>Your Information</h1>}
                            {step == 1 && <h1>Please verify</h1>}
                            {step == 2 && <h1>Membership Plan</h1>}
                            {step == 3 && <h1>Order Summary</h1>}
                            {step != 0 && <div className="register-form-back" onClick={onPrev}><ArrowLeftOutlined /></div>}
                        </div>
                        <ConfigProvider
                            button={{
                                className: styles.linearGradientButton,
                            }}
                        >
                            <Spin spinning={loading}>
                                <div
                                    className="auth-form"
                                >
                                    {step == 0 && <><div className="signup-form-personal" style={{ padding: 20 }}>
                                        <Radio.Group onChange={(e) => {
                                            setSignupForm({ ...signupForm, is_child: e.target.value });
                                        }} value={signupForm.is_child} className="signup-custom-radio-group">
                                            <Row gutter={[12, 12]}>
                                                <Col xs={24} md={12}>
                                                    <Radio.Button value="individual">Individual User Account</Radio.Button>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Radio.Button value="parent">Parent/Guardian Account</Radio.Button>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </div>
                                        {signupForm.is_child == "individual" ?
                                            <SignupPersonalRect
                                                formData={signupForm}
                                                setFormData={setSignupForm}
                                                errorFields={errorFields}
                                                setErrorFields={setErrorFields}
                                            /> : <SignupParentRect
                                                formData={signupForm}
                                                setFormData={setSignupForm}
                                                errorFields={errorFields}
                                                setErrorFields={setErrorFields}
                                            />}
                                    </>}
                                    {step == 1 && <SignupOtp
                                        email={signupForm.email}
                                        first_name={signupForm.first_name}
                                        verified={verified}
                                        loading={loading}
                                        setLoading={setLoading}
                                        setVerified={setVerified}
                                    />}
                                    {step == 2 && <MembershipWidget
                                        subscriptionList={subscriptionList}
                                        setSubscriptionList={setSubscriptionList}
                                        selSubscription={selSubscription}
                                        setSelSubscription={setSelSubscription}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />}
                                    {step == 3 && <>
                                        <SubscriptionOrder
                                            selSubscription={selSubscription}
                                            termCheck={termCheck}
                                            setTermCheck={setTermCheck}
                                            loading={loading}
                                            setLoading={setLoading}
                                        />
                                        <div>
                                            <Elements stripe={stripePromise}>
                                                <SubscriptionForm
                                                    onPayFinished={onPayFinished}
                                                    selSubscription={selSubscription}
                                                    email={signupForm.email}
                                                    loading={loading}
                                                    setLoading={setLoading}
                                                    termCheck={termCheck} />
                                            </Elements>
                                        </div>
                                    </>}
                                    <div className="signup-form-personal">
                                        <Row className='signup-form-row' gutter={[16, 16]}>
                                            <Col xs={24} md={24}>
                                                <Form.Item>
                                                    <p style={{ color: 'red', marginBottom: '10px' }}>{signupResponse}</p>
                                                    <Row justify={step == 3 ? "center" : "space-between"}>
                                                        <Col>{(step == 1 || step == 2) &&
                                                            <Button type="primary" onClick={onPrev} block style={{ width: '140px', padding: '20px' }}>
                                                                <ArrowLeftOutlined /> Prev
                                                            </Button>}
                                                        </Col>
                                                        <Col>
                                                            <Button type="primary" onClick={onSubmit} block style={step == 3 ? { width: '240px', padding: '20px', fontSize: 20, display: "none" } : { minWidth: '140px', padding: '20px' }}>
                                                                {
                                                                    (
                                                                        step != 3 ? <>
                                                                            Next <ArrowRightOutlined />
                                                                        </> : loading ? <>
                                                                            <Spin size="small" style={{ marginRight: 8, color: 'white' }} />
                                                                        </> : <>
                                                                        </>
                                                                    )
                                                                }
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Spin>
                        </ConfigProvider>
                    </Spin>
                </div>
            </div>
        </HomeLayout>
    )
}

export default withRouter(SignUp);