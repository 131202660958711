import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Button, ConfigProvider, Form, Input, Checkbox, Spin, Modal, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import axios from 'axios';
import './index.css';
import useStyle from './useStyle';
import ModalRegistration from "components/Admin/Partials/ModalRegistration";
import Logo from "assets/png/logo.png";

const Signin = (props) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [signinResponse, setSigninResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [modalLoginConfirm, setLoginConfirm] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const { styles } = useStyle();
    const onSigninFinish = async (values) => {
        setSigninResponse("");
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("password", values.password);
            formData.append("email", values.email);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/signin`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('clientToken')}`,
                    },
                }
            );

            if (response.data.status == "success") {
                console.log('signin', response.data);
                localStorage.setItem("client_token", response.data.token);
                localStorage.setItem("name", response.data.name);
                localStorage.setItem("email", response.data.email);
                localStorage.setItem("user_id", response.data.user_id);
                localStorage.setItem("subscription_status", response.data.subscription_status);
                setIsAuth(true);
                if (response.data.subscription_status == "active") {
                    setLoginConfirm(true);    
                } else {
                    window.location.href="/";
                }
            } else if (response.data.message == "You are not verified yet. Please verify.") {
                setSigninResponse(response.data.message);
                setTimeout(() => {
                    window.location.href = "/otp-verify"; // Redirect after 2 seconds
                }, 2000);
            } else {
                setSigninResponse(response.data.message);

            }
        } catch (error) {
            console.error("Signin error:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (localStorage.getItem("client_token")) {
            window.location.href = "/";
        }
    }, [])
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleChange = (e) => {
        setSigninResponse("");
    };

    const handleBack = () => {
        window.location.href = "/";
    }

    const handleShowLogin = (type) => {
        if (type == "ok") {
            setIsLoggingIn(false);
            setModal2Open(true);
            setLoginConfirm(false);
        } else {
            window.location.href = "/";  // Navigate to the signin page if not authenticated
        }
    };

    return (
        <>
            <div className="client-auth-content">
                <div className="client-auth-card">
                    <a href="#" onClick={handleBack} type="button" className="btn-back-icon btn-back-signin-icon"><ArrowLeftOutlined style={{ fontSize: '20px' }} /></a>
                    <div className="mb-30 center">
                        <img src={Logo} alt="Modal Image" />
                    </div>
                    <h2 className="mb-30 text-center">Login to your account</h2>
                    <ConfigProvider
                        button={{
                            className: styles.linearGradientButton,
                        }}
                    >
                        <Form
                            name="signup"
                            layout="vertical"
                            onFinish={onSigninFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: "Please enter your email address" },
                                    { type: "email", message: "Please enter a valid email" },
                                ]}
                            >
                                <Input placeholder="john.doe@example.com" onChange={handleChange} className="client-auth-input" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                validateStatus={passwordsMatch ? '' : 'error'} // Remove icons by leaving the status blank
                                rules={[
                                    { required: true, message: 'Please enter your password' },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Password"
                                    onChange={handleChange}
                                    iconRender={(visible) =>
                                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                    }
                                    visibilityToggle={{
                                        visible: passwordVisible,
                                        onVisibleChange: setPasswordVisible,
                                    }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Row justify={"space-between"} align="middle" gutter={[16, 16]}>
                                    <Col><Checkbox>Keep me signed in</Checkbox>
                                    </Col>
                                    <Col><Link to="/forgot-password" className="client-auth-text">Forgot Password?</Link>
                                    </Col>
                                </Row>
                            </Form.Item>

                            <Form.Item>
                                <p style={{ color: 'red', marginTop: '-10px', marginBottom: '10px' }}>{signinResponse}</p>
                                <Button type="primary" htmlType="submit" block>
                                    {loading ? (
                                        <>
                                            <Spin size="small" style={{ marginRight: 8, color: 'white' }} />
                                            Loading...
                                        </>
                                    ) : (
                                        <>
                                            Sign in
                                        </>
                                    )}
                                </Button>
                            </Form.Item>
                            <div className="center">
                                <Link to="/signup" className="client-auth-text">Don't have an account?</Link>
                            </div>
                        </Form>
                    </ConfigProvider>
                </div>
            </div>
            {modal2Open && <ModalRegistration modal2Open={modal2Open} setModal2Open={setModal2Open} />}
            <Modal
                centered
                open={modalLoginConfirm}
                onOk={() => handleShowLogin("ok")}
                onCancel={() => handleShowLogin("cancel")}
                okText="Yes"
                cancelText="No"
                closable={false} // Removes the close (×) button
            >
                <div style={{ textAlign: 'center' }}>
                    <Link to="/">
                        <img src={Logo} alt="Modal Image" style={{ maxWidth: '100%', marginBottom: '20px' }} />
                    </Link>
                    <div style={{ marginBottom: '20px', fontSize: '18px' }}>
                        <div style={{ margin: 'auto', width: 'auto', textAlign: 'left' }}>
                            Press Yes to continue to Registration screen, <br />Press No to continue browsing the site
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default withRouter(Signin);