import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Select, Tabs, Modal, message } from 'antd';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Toast from 'layouts/Partials/Toast';
import '../Dashboard/index.css';
import { showAddSettingDlg } from './AddSettingDlg/showAddSettingDlg';
import DataTable from './DataTable';
import { numberWithCommas } from 'constants/global';

const Payment = ({ history }) => {
  const [dataSource, setDataSource] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login');
    } else {
      setDataSource([]);
      setFilteredData([]);
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    const formData = new FormData();
    let dataUrl = `${process.env.REACT_APP_API_BASE_URL}api/payment`;
    try {
      const response = await axios.get(dataUrl, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setTotalAmount(response.data.total);
      const fetchedData = response.data.data.map((item, index) => ({
        ...item,
        key: String(item.id),
      }));
      setDataSource(fetchedData);
      setFilteredData(fetchedData);
    } catch (err) {
      Toast('There was an error on server!', 2);
    }
  };


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };

  const handleDownload = async (record) => {
    const formData = new FormData();
    formData.append('id', record.id);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/payment/download_invoice`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (response.data.status === "success") {
      window.open(response.data.url, "_target");
    } else {
      Toast("Something went wrong in while generating invoice.", 2);
    }
  }

  return (
    <AdminLayout>
      <Row className="table-nav">
        <Col className='d-flex'>
          <Input
            placeholder="Search..."
            value={searchText}
            allowClear={true}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col style={{ fontWeight: 500, fontSize: 16 }}>
          Total: {numberWithCommas(totalAmount)} USD
        </Col>
      </Row>


      <div style={{ overflowX: 'auto', marginTop: 20, border: '1px solid #f0f0f0', borderRadius: '4px', overflowY: 'hidden' }}>
        <DataTable
          filteredData={filteredData}
          pagination={pagination}
          setPagination={setPagination}
          handleDownload={handleDownload}
        />
      </div>
    </AdminLayout>
  );
};

export default Payment;